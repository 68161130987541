@import url("https://fonts.cdnfonts.com/css/gotham");
@import url("https://fonts.cdnfonts.com/css/myriad-pro");

// vars
:root {
  --main-font: "Gotham", sans-serif;
  --titles-font: "myriad-pro", sans-serif;

  --main-color: Maroon;
  --shadow-color: Indigo;
  --secondary-color: MidNightBlue;
  --main-contrast: white;
}
