.hidden-word {
  background-color: var(--main-color);
  color: var(--main-color);
  border-radius: 4px;
  padding: 0;
  font-family: var(--main-font);
  font-weight: 600;
  cursor: pointer;
  &.revealed {
    background-color: transparent;
  }
}
