@import "https://fonts.cdnfonts.com/css/gotham";
@import "https://fonts.cdnfonts.com/css/myriad-pro";
:root {
  --main-font: "Gotham", sans-serif;
  --titles-font: "myriad-pro", sans-serif;
  --main-color: Maroon;
  --shadow-color: Indigo;
  --secondary-color: MidNightBlue;
  --main-contrast: white;
}

* {
  box-sizing: border-box;
}

body {
  font-family: var(--main-font);
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
}

#playground {
  padding-top: 1rem;
  padding-bottom: 50px;
}

section {
  max-width: 800px;
  margin: 0 auto;
}

.hidden {
  display: none;
}

.hidden-word {
  background-color: var(--main-color);
  color: var(--main-color);
  font-family: var(--main-font);
  cursor: pointer;
  border-radius: 4px;
  padding: 0;
  font-weight: 600;
}

.hidden-word.revealed {
  background-color: #0000;
}

@media screen and (width <= 767px) {
  p {
    padding: 0 1rem;
  }
}

.dialogue {
  margin: 0 0 24px;
  line-height: 24px;
}

.dialogue.pre {
  color: #a9a9a9;
}

.dialogue.pre > .role {
  color: #4682b4;
}

.dialogue.main {
  color: #000;
}

.dialogue.main > .role {
  color: #191970;
  font-weight: bolder;
}

.dialogue.continue {
  margin-bottom: 0;
}

button, select {
  background-color: var(--secondary-color);
  color: var(--main-contrast);
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 1px 2px var(--shadow-color);
  border: none;
  border-radius: 4px;
  margin: 4px 2px;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: background-color .3s, color .3s;
  display: inline-block;
}

button:hover, select:hover {
  background-color: var(--shadow-color);
  color: #fff;
  box-shadow: 0 0 1px 2px var(--main-color);
}

nav {
  background: #fff;
  flex-shrink: 0;
  justify-content: space-between;
  column-gap: 5px;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  position: fixed;
  bottom: 0;
  overflow-x: auto;
}

nav select {
  flex-grow: 1;
  flex-shrink: 1;
}

nav button {
  box-shadow: 0 0 1px 2px var(--shadow-color);
  width: 100%;
  height: 35px;
  margin: 0;
  position: relative;
  top: -18.5px;
}

nav button:hover {
  box-shadow: 0 0 1px 2px var(--main-color);
}

select {
  -webkit-appearance: none;
  appearance: none;
}
/*# sourceMappingURL=index.efff9be2.css.map */
