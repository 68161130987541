button, select {
  background-color: var(--secondary-color);
  border: none;
  color: var(--main-contrast);
  padding: 8px 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  font-weight: 500;

  &:hover {
    background-color: var(--shadow-color);
    color: white;
  }

  box-shadow: 0 0 1px 2px var(--shadow-color);
  &:hover {
    box-shadow: 0 0 1px 2px var(--main-color);
  }
}

nav {
  position: fixed;
  background: white;
  bottom: 0;
  max-width: 100vw;
  flex-shrink: 0;
  width: 100vw;
  display: flex;
  overflow-x: auto;
  justify-content: space-between;
  column-gap: 5px;
  select {
    flex-grow: 1;
    flex-shrink: 1;
  }
  button {
    width: 100%;
    height: 35px;
    position: relative;
    margin: 0;
    top: -18.5px;
    box-shadow: 0 0 1px 2px var(--shadow-color);
    &:hover {
      box-shadow: 0 0 1px 2px var(--main-color);
    }
  }
}

select {
  appearance: none;
}